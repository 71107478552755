import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/default"

import AudioPlayer from "../../../components/audio-player"
import Box from "../../../components/box"
import Breadcrumb from "../../../components/breadcrumb"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Inline from "../../../components/inline"
import PageTitle from "../../../components/page-title"
import QuotePrimary from "../../../components/quote-primary"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"
import Text from "../../../components/text"

import LeichteSpracheNext from "../../../content/shared/leichte-sprache-next"

const LeichteSpracheFreunde = () => {
  const data = useStaticQuery(graphql`
    query LeichteSpracheFreundeIndexQuery {
      magenau: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/magenau.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, layout: FIXED)
        }
      }
      bundesbuch01: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/bundesbuch-01.png"
        }
      ) {
        ...largeImage
      }
      bundesbuch04: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/bundesbuch-04.png"
        }
      ) {
        ...largeImage
      }
      hoelderlin: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/hoelderlin.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, layout: FIXED)
        }
      }
      goethe: file(relativePath: { eq: "leichte-sprache/goethe.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, layout: FIXED)
        }
      }
      schiller: file(relativePath: { eq: "leichte-sprache/schiller.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, layout: FIXED)
        }
      }
      schelling: file(relativePath: { eq: "leichte-sprache/schelling.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, layout: FIXED)
        }
      }
      sinclair: file(relativePath: { eq: "leichte-sprache/sinclair.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, layout: FIXED)
        }
      }
      hegel: file(relativePath: { eq: "leichte-sprache/hegel.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, layout: FIXED)
        }
      }
      neuffer: file(
        relativePath: {
          eq: "dauerausstellung/04-tuebinger-hoffnungen/neuffer.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Hölderlin hatte viele Freunde"
        description="Als Student hat Hölderlin  viele Freunde. Viele davon sind auch berühmt geworden."
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Über Hölderlin",
                link: "/leichte-sprache/ueber-hoelderlin",
              },
              {
                title: "Freunde",
                link: "/leichte-sprache/ueber-hoelderlin/freunde",
              },
            ]}
          />
          <PageTitle>Hölderlin hatte viele Freunde</PageTitle>
          <Paragraph>
            Als Student hat Hölderlin viele Freunde. <br />
            Viele davon sind auch berühmt geworden.
          </Paragraph>
          <Constrain align="left">
            <AudioPlayer src="leichte-sprache/05-freunde" />
          </Constrain>
        </Stack>

        <Constrain>
          <Stack>
            <Stack space={4}>
              <Inline>
                <Box
                  sx={{
                    display: "inline-block",
                    borderRadius: "9999em",
                    overflow: "hidden",
                  }}
                >
                  <Image
                    alt="Porträt von Rudolf Friedrich Heinrich Magenau"
                    image={data.magenau.childImageSharp.gatsbyImageData}
                    sx={{ verticalAlign: "bottom" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "inline-block",
                    borderRadius: "9999em",
                    overflow: "hidden",
                  }}
                >
                  <Image
                    alt="Porträt von Christian Ludwig Neuffer"
                    image={data.neuffer.childImageSharp.gatsbyImageData}
                    sx={{ verticalAlign: "bottom" }}
                  />
                </Box>
              </Inline>
              <Heading as="h2" level={2}>
                Magenau und Neuffer
              </Heading>
            </Stack>
            <Paragraph>
              Rudolf Friedrich Heinrich Magenau ist ein Freund von Hölderlin.{" "}
              <br />
              Hölderlin geht mit Magenau zur Schule. <br />
              In Tübingen lernen sie Christian Ludwig Neuffer kennen. <br />
              Hölderlin, Neuffer und Magenau treffen sich oft. <br />
              Sie schreiben zusammen Gedichte. <br />
              Die Gedichte schreiben sie alle in einem Buch auf.
            </Paragraph>
            <Paragraph>
              Neuffer und Magenau schreiben auch später noch Gedichte. <br />
              Sie arbeiten aber beide auch als Pfarrer. <br />
              Hölderlin arbeitet nie als Pfarrer.
            </Paragraph>
          </Stack>
        </Constrain>

        <Stack>
          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Lied der Freundschaft
              </Heading>
              <Paragraph>
                Mit Magenau und Neuffer schreibt Hölderlin auch Gedichte. <br />
                Die Gedichte tragen sie in ein gemeinsames Buch ein.
              </Paragraph>
            </Stack>
          </Constrain>
          <Image
            attribution="WLB Stuttgart"
            image={data.bundesbuch01.childImageSharp.gatsbyImageData}
            alt="Titelei des Bundesbuchs mit handschriftlichen Namenseinträgen"
          />
          <Constrain>
            <Stack>
              <Paragraph>
                In das Buch schreiben Magenau, Neuffer und Hölderlin ihre Namen
                hinein:
              </Paragraph>

              <Stack space={6}>
                <Columns alignY="center">
                  <Column width="content">
                    <Box
                      sx={{
                        display: "inline-block",
                        borderRadius: "9999em",
                        overflow: "hidden",
                      }}
                    >
                      <Image
                        alt="Porträt von Rudolf Friedrich Heinrich Magenau"
                        image={data.magenau.childImageSharp.gatsbyImageData}
                        sx={{ verticalAlign: "bottom" }}
                      />
                    </Box>
                  </Column>
                  <Column>
                    <Text size={[3, 3, 4]}>
                      Rudolf Friedrich Heinrich Magenau, <br />
                      aus Marck-Gröningen.
                    </Text>
                  </Column>
                </Columns>
                <Columns alignY="center">
                  <Column width="content">
                    <Box
                      sx={{
                        display: "inline-block",
                        borderRadius: "9999em",
                        overflow: "hidden",
                      }}
                    >
                      <Image
                        alt="Porträt von Christian Ludwig Neuffer"
                        image={data.neuffer.childImageSharp.gatsbyImageData}
                        sx={{ verticalAlign: "bottom" }}
                      />
                    </Box>
                  </Column>
                  <Column>
                    <Text size={[3, 3, 4]}>
                      Christian Ludwig Neuffer, <br />
                      von Stuttgardt.
                    </Text>
                  </Column>
                </Columns>
                <Columns alignY="center">
                  <Column width="content">
                    <Box
                      sx={{
                        display: "inline-block",
                        borderRadius: "9999em",
                        overflow: "hidden",
                      }}
                    >
                      <Image
                        alt="Porträt von Johann Christian Friedrich Hölderlin"
                        image={data.hoelderlin.childImageSharp.gatsbyImageData}
                        sx={{ verticalAlign: "bottom" }}
                      />
                    </Box>
                  </Column>
                  <Column>
                    <Text size={[3, 3, 4]}>
                      Johann Christian Friedrich Hölderlin, <br />
                      aus Lauffen.
                    </Text>
                  </Column>
                </Columns>
              </Stack>
              <Paragraph>
                Hölderlin schreibt auch ein Lied in das Buch hinein. Das Lied
                der Freundschaft.
              </Paragraph>
            </Stack>
          </Constrain>
          <Image
            image={data.bundesbuch04.childImageSharp.gatsbyImageData}
            attribution="WLB Stuttgart"
            alt="Zwei Seiten zeigen die ersten Strophen von Hölderlins ›Lied der Freundschaft‹"
          />
          <Constrain align="right">
            <QuotePrimary author="Friedrich Hölderlin: Lied der Freundschaft">
              <span>Frei, wie Götter an dem Mahle,</span>
              <span>Singen wir um die Pokale,</span>
              <span>Wo der edle Trank erglüht,</span>
              <span>Voll von Schauern, ernst und stille,</span>
              <span>In des Dunkels heil'ger Hülle</span>
              <span>Singen wir der Freundschaft Lied. ...</span>
            </QuotePrimary>
          </Constrain>
        </Stack>

        <Constrain>
          <Stack>
            <Stack space={4}>
              <Inline>
                <Box
                  sx={{
                    display: "inline-block",
                    borderRadius: "9999em",
                    overflow: "hidden",
                  }}
                >
                  <Image
                    alt="Porträt von Georg Wilhelm Friedrich Hegel"
                    image={data.hegel.childImageSharp.gatsbyImageData}
                    sx={{ verticalAlign: "bottom" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "inline-block",
                    borderRadius: "9999em",
                    overflow: "hidden",
                  }}
                >
                  <Image
                    alt="Porträt von Friedrich Wilhelm Schelling"
                    image={data.schelling.childImageSharp.gatsbyImageData}
                    sx={{ verticalAlign: "bottom" }}
                  />
                </Box>
              </Inline>
              <Heading as="h2" level={2}>
                Hegel und Schelling
              </Heading>
            </Stack>
            <Paragraph>
              In Tübingen findet Hölderlin noch andere Freunde: <br />
              Georg Wilhelm Friedrich Hegel und Friedrich Wilhelm Joseph
              Schelling. <br />
              Hegel und Schelling studieren mit Hölderlin. <br />
              Sie wohnen zusammen in einem Zimmer. <br />
              Nach dem Studium sehen sie sich noch manchmal. <br />
              Und sie schreiben sich viele Briefe. <br />
              Hegel und Schelling wollen auch nicht Pfarrer werden. <br />
              Hegel und Schelling werden wichtige deutsche Philosophen.
            </Paragraph>

            <Paragraph>
              Ein Philosoph ist ein Mensch, <br />
              der über viele Sachen nach∙denkt. <br />
              Zum Beispiel über den Menschen. <br />
              Er denkt darüber nach: <br />
              Was ist der Mensch?
            </Paragraph>

            <Paragraph>
              Und er denkt über das Leben nach. <br />
              Zum Beispiel denkt er darüber nach: <br />
              Warum lebt der Mensch? <br />
              Was ist wichtig im Leben? <br />
              Ein Philosoph schreibt seine Gedanken auf. <br />
              Dann können sie auch andere Menschen lesen.
            </Paragraph>
          </Stack>
        </Constrain>

        <Constrain>
          <Stack>
            <Stack space={4}>
              <Box
                sx={{
                  display: "inline-block",
                  borderRadius: "9999em",
                  overflow: "hidden",
                }}
              >
                <Image
                  alt="Porträt von Isaac von Sinclair"
                  image={data.sinclair.childImageSharp.gatsbyImageData}
                  sx={{ verticalAlign: "bottom" }}
                />
              </Box>
              <Heading as="h2" level={2}>
                Von Sinclair
              </Heading>
            </Stack>
            <Paragraph>
              Hölderlin studiert auch eine Zeit lang in Jena. <br />
              Dort lernt er seinen Freund Isaac von Sinclair kennen. <br />
              Sie studieren zusammen. <br />
              Hölderlin wohnt mit Isaac von Sinclair in einem Garten∙haus.{" "}
              <br />
              Später wohnt er auch bei Sinclair in Bad Homburg.
            </Paragraph>
          </Stack>
        </Constrain>

        <Constrain>
          <Stack>
            <Stack space={4}>
              <Inline>
                <Box
                  sx={{
                    display: "inline-block",
                    borderRadius: "9999em",
                    overflow: "hidden",
                  }}
                >
                  <Image
                    alt="Porträt von Johann Wolfgang Goethe"
                    image={data.goethe.childImageSharp.gatsbyImageData}
                    sx={{ verticalAlign: "bottom" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "inline-block",
                    borderRadius: "9999em",
                    overflow: "hidden",
                  }}
                >
                  <Image
                    alt="Porträt von Friedrich Schiller"
                    image={data.schiller.childImageSharp.gatsbyImageData}
                    sx={{ verticalAlign: "bottom" }}
                  />
                </Box>
              </Inline>
              <Heading as="h2" level={2}>
                Goethe und Schiller als Vorbilder
              </Heading>
            </Stack>
            <Paragraph>
              Hölderlin lernt in Jena auch Johann Wolfgang von Goethe und
              Friedrich Schiller kennen. <br />
              Hölderlin hat schon Bücher von Goethe und Schiller gelesen. <br />
              Hölderlin und Schiller haben sich auch schon viele Briefe
              geschrieben. <br />
              Jetzt lernt Hölderlin Goethe und Schiller endlich richtig kennen.{" "}
              <br />
              Goethe und Schiller waren berühmte Dichter. <br />
              Schiller war ein Vorbild für Hölderlin. <br />
              Das bedeutet: <br />
              Hölderlin wollte so sein wie Schiller. <br />
              Er wollte so gute Gedichte schreiben wie Schiller.
            </Paragraph>
          </Stack>
        </Constrain>

        <Constrain>
          <Stack>
            <Heading as="h2" level={2}>
              Hölderlin als Vorbild
            </Heading>
            <Paragraph>
              Später war auch Hölderlin für viele Menschen ein Vorbild. <br />
              Manche haben Gedichte oder Bücher über Hölderlin geschrieben.{" "}
              <br />
              Manche haben Bilder von Hölderlin gemalt. <br />
              Und Bilder zu seinen Gedichten. <br />
              Manche Musiker haben Musik aus Hölderlins Gedichten gemacht.{" "}
              <br />
              Die Musik können Sie im Hölderlin∙turm hören.
            </Paragraph>
          </Stack>
        </Constrain>

        <LeichteSpracheNext variant="verliebt" />
      </SectionSpacing>
    </Layout>
  )
}

export default LeichteSpracheFreunde
